import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import { Link } from "gatsby"
import PdfButton from "../components/PdfButton"
import Collapse from "../components/Collapse"
import { useAxiosClient } from "../service/useAxiosClient"
import DownloadBtn from "../components/DownloadBtn"
import TitlePage from "../components/TitlePage"

export default function Newsroom({ location }) {
  const path = [{ label: "Inicio", to: "/" }, { label: "Sala de prensa" }]

  const [press, setPress] = useState({})

  const [{ data: dataPress }] = useAxiosClient({
    url: `/publications/web/press-room/`,
    method: "GET",
  })

  useEffect(() => {
    if (dataPress) {
      setPress(dataPress)
    }
  }, [dataPress])

  const renderFiles = items => {
    if (!items || !items.length) return

    return items.map(item => {
      return (
        <PdfButton
          className="Newsroom-button"
          text={item?.title}
          link={item?.file || ""}
          isExternal
        />
      )
    })
  }

  const renderContent = data => {
    if (!data || !data.length) return

    return data.map(item => (
      <div className="Newsroom-item">
        <span className="Newsroom-date">{item?.publication_date}</span>
        <Link
          state={{ id: item?.pk }}
          className="Newsroom-link"
          to={`/actualidad/${item?.category.slug}/${item?.slug}`}
        >
          {item?.title}
        </Link>
        {renderFiles(item?.files_clipping)}
      </div>
    ))
  }

  const renderNews = () => {
    const names = Object.keys(press)

    return (
      Object.values(press) &&
      Object.values(press).map((item, index) => (
        <Collapse title={names[index]}>{renderContent(item)}</Collapse>
      ))
    )
  }

  return (
    <Layout>
      <TitlePage title="Sala de prensa" url={location.pathname} />
      <Hero image="/imgs/actualidad-banner.jpg" text="Sala de prensa" />
      <div className="container950">
        <div className="Newsroom">
          <Breadcrumb path={path} />
          <TitleBlock
            title="Sala de prensa EFPA España"
            icon="far fa-newspaper"
          />
          <div className="Newsroom-wrapperBtn">
            <DownloadBtn
              text="Dossier de prensa"
              link="/docs/EFPA_Espana_Dossier_de_prensa.pdf"
              className="w-50"
            />
          </div>
          <div className="Newsroom-content">{renderNews()}</div>
        </div>
      </div>
    </Layout>
  )
}
